import React, { useCallback, useMemo, useRef, useState } from 'react';

import RemoveButton from '~/app/components/ItemPage/sections/lib/RemoveButton';
import { DialogBoxHeader } from '~/app/components/DialogBox/DialogBoxHeader';
import { useItemContext } from '~/app/components/ItemPage/ItemPageContext';
import countriesByCode from '~/app/lib/utils/countriesByCode';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import { SelectOption } from '~/app/components/Select2';
import CountryFlag from '~/app/components/CountryFlag';
import { isEmptyObject } from '~/app/lib/utils/object';
import Form, { FormApi } from '~/app/components/Form';
import InputLabel from '~/app/components/InputLabel';
import Clickable from '~/app/components/Clickable';
import { Select } from '~/app/components/Select2';
import Text from '~/app/components/Text';
import { useI18n } from '~/app/lib/i18n';
import Box from '~/app/components/Box';

import { useEditActions } from '../../../useEditActions';
import { AgeGateDialogRenderStage } from '../types';
import { DEFAULT_TERRITORY } from '../constants';

import { AgeInput } from './AgeInput';

type FormValues = {
  territory: string;
  age: string;
};

export const AddStage = React.memo<AgeGateDialogRenderStage>(
  ({ close, setStage, paddingX, paddingY }) => {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const formApiRef = useRef<FormApi>(null);
    const { setAddon } = useEditActions();
    const { addons } = useItemContext();
    const { t } = useI18n();

    const addonData = addons[AddonTypes.AGE_GATE] ?? {};

    const territoryOptions = useMemo(
      () =>
        [DEFAULT_TERRITORY]
          .concat(Object.entries(countriesByCode))
          .filter(([code]) => !addonData.hasOwnProperty(code))
          .map<SelectOption>(([code, name]) => ({
            id: code,
            text: name,
            Icon: () => (
              <CountryFlag
                countryCode={code}
                size="1.8rem"
                margin="0.1em 0.65em 0 0"
                saturation={0.7}
              />
            ),
          })),
      [addonData]
    );

    const onRemove = useCallback(() => {
      if (isEmptyObject(addonData)) {
        close();
      } else {
        setStage('default');
      }
    }, [addonData]);

    return (
      <Box>
        <Box zIndex={2} style={{ position: 'sticky', top: 0 }}>
          <DialogBoxHeader
            title={t('itemEdit.ageGateAddon.title')}
            onBackClick={onRemove}
            renderRight={useCallback(
              ({ textProps }) => (
                <Clickable
                  testId="ageGateAdd"
                  onClick={() => formApiRef.current?.submit()}
                  isDisabled={isSubmitDisabled}
                >
                  <Text {...textProps}>{t('app.actions.add')}</Text>
                </Clickable>
              ),
              [isSubmitDisabled]
            )}
          />
        </Box>

        <Box padding={`0 ${paddingX} ${paddingY}`}>
          <Form<FormValues>
            apiRef={formApiRef}
            onSubmit={({ values }) => {
              setAddon(AddonTypes.AGE_GATE, {
                ...addonData,
                [values.territory]: Number(values.age),
              });

              setStage('default');
            }}
          >
            <InputLabel
              margin="0 0 2.3rem"
              value={t('itemEdit.labels.territory')}
              description={t('itemEdit.ageGateAddon.territoryHint')}
            >
              <Select
                testId="ageGateTerritorySelect"
                width="100%"
                name="territory"
                options={territoryOptions}
                placeholder={t('itemEdit.ageGateAddon.territoryPlaceholder')}
                searchFeature={{ type: 'search' }}
                isRequired
                onChange={({ value }) => {
                  setIsSubmitDisabled(!value);
                }}
              />
            </InputLabel>
            <InputLabel
              value={t('itemEdit.labels.ageLimit')}
              description={t('itemEdit.ageGateAddon.ageHint')}
            >
              <AgeInput />
            </InputLabel>
          </Form>

          <RemoveButton
            text="Remove"
            testId="ageGateRemove"
            margin={`${paddingX} 0 0`}
            onClick={onRemove}
          />
        </Box>
      </Box>
    );
  }
);
