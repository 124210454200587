import { cloneDeep } from 'lodash-es';
import { useMemo } from 'react';
import Debug from 'debug';

import {
  addCustomLinkAction,
  clearAddonAction,
  setAddonAction,
  setLayoutAction,
  updateCustomObjectAction,
} from '~/app/lib/store/editStage/actions';

import { createSelectAllLinks } from '~/app/lib/store/editStage/selectors';
import { AddonsConfig, AddonTypes } from '~/app/lib/songwhipApi/types';
import { get, set, unset } from '~/app/lib/utils/objectPathUtils';
import { useDispatch } from '~/app/lib/store/redux';
import { useItemContext } from '../ItemPageContext';

import {
  ItemPageLayoutDefinition,
  LayoutSlotIds,
  PageSectionDefinition,
} from '../types';

const debug = Debug('songwhip/useEditActions');

/**
 * A convenient hook that exposes all editStage actions pre-wrapped w/ dispatch
 */
export const useEditActions = () => {
  const selectAllLinks = useMemo(() => createSelectAllLinks(), []);
  const { layout } = useItemContext();
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      selectAllLinks,

      /**
       * Append a new PageSection
       */
      addLayoutSection: ({
        pageSection,
        index,
      }: {
        pageSection: PageSectionDefinition;
        index?: number;
      }) => {
        const nextLayout = cloneDeep(layout);

        const slotId = LayoutSlotIds.MAIN;

        if (index === undefined) {
          index = Math.max(nextLayout.main.length, 0);
        }

        const layoutPath = `${slotId}[${index}]`;
        nextLayout.main.splice(index, 0, pageSection);

        dispatch(
          setLayoutAction({
            layout: nextLayout,
          })
        );

        return {
          layoutPath,
          index,
        };
      },

      /**
       * Make a change to an existing PageSection
       */
      updateLayoutSection: <TComponentProps = any>(
        params: Omit<ApplyLayoutChangeParams<TComponentProps>, 'layout'>
      ) => {
        const nextLayout = applyLayoutChange({
          layout,
          ...params,
        });

        dispatch(
          setLayoutAction({
            layout: nextLayout,
          })
        );
      },

      removeLayoutSection: ({
        layoutSectionPath,
      }: {
        layoutSectionPath: string;
      }) => {
        // clone layout then remove the PageSection by path
        const nextLayout = cloneDeep(layout);
        unset(nextLayout, layoutSectionPath);

        dispatch(
          setLayoutAction({
            layout: nextLayout,
          })
        );
      },

      setAddon: <TAddonTypes extends AddonTypes>(
        addonType: TAddonTypes,
        value: AddonsConfig[TAddonTypes] | null
      ) => dispatch(setAddonAction(addonType, value)),

      clearAddon: (...args: Parameters<typeof clearAddonAction>) =>
        dispatch(clearAddonAction(...args)),

      updateCustomObject: (
        ...args: Parameters<typeof updateCustomObjectAction>
      ) => dispatch(updateCustomObjectAction(...args)),

      addCustomLinkObject: (...args: Parameters<typeof addCustomLinkAction>) =>
        dispatch(addCustomLinkAction(...args)),
    }),
    [layout]
  );
};

interface ApplyLayoutChangeParams<TComponentProps = any> {
  layout: ItemPageLayoutDefinition;
  sectionPath: string;
  changedProps: Partial<TComponentProps>;
}

const applyLayoutChange = ({
  layout,
  sectionPath,
  changedProps,
}: ApplyLayoutChangeParams): ItemPageLayoutDefinition => {
  const section = get(layout, sectionPath);

  if (!section) {
    debug('unable to find component %s in layout', sectionPath, layout);
    throw new Error(`unable to find component w/ path: ${sectionPath}`);
  }

  const nextSection = {
    ...section,

    props: {
      ...section.props,
      ...changedProps,
    },
  };

  debug('apply change to layout', {
    layout,
    sectionPath,
    changedProps,
    nextSection,
  });

  const layoutNext = cloneDeep(layout);
  set(layoutNext, sectionPath, nextSection);

  return layoutNext;
};
